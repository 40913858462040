<script setup lang="ts">
  import Breadcrumbs from '~/UI/Breadcrumbs.vue';
  import ClinicInfo from '~/components/ClinicInfo.vue';
  import { PageInfoService } from '~/EastclinicVueApi';

  const { pageInfo } = storeToRefs(usePageInfoStore());
  const { currentClinic } = storeToRefs(useClinicsStore());

  const isClinicInfo = computed(() => {
    return pageInfo.value?.id === 6 && !!currentClinic.value;
  });

  const { data: catalogData } = await useLazyAsyncData(
    `catalogData-${pageInfo.value?.id}`,
    () => PageInfoService.getCatalogData(pageInfo.value) // use service, because save catalog data inside this layout
  );
</script>

<template>
  <div>
    <Header />
    <div v-if="pageInfo?.type !== 'doctor' && pageInfo?.type !== 'cabinet'" class="white-container">
      <SearchPanel class="layout-search-panel" />
      <div class="main-container">
        <AwardsCarouselLayout />
        <Breadcrumbs v-if="pageInfo?.type!=='dismissDoctor'" :items="pageInfo?.breadcrumbs ?? []" />
        <div>
          <ClinicInfo v-if="isClinicInfo" />
        </div>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
    <div class="doctors-bottom">
      <Catalog v-if="pageInfo?.type !== 'doctor' && pageInfo?.type !== 'cabinet' && catalogData && pageInfo?.id !== 7806" :title="translateCatalogData(Object.keys(catalogData.data)[0])" :data="Object.values(catalogData.data)[0]" />
      <Footer />
    </div>
  </div>
</template>

<style scoped lang="scss">
.layout-search-panel {
  padding-top: 8px;
  margin-bottom: 24px;
  @media (max-width: 1023px) {
    margin-top: 12px;
    margin-bottom: 16px;
  }
}
.mb-6 {
  padding-bottom: 24px;
}
.doctors-bottom {
  display: flex;
  flex-direction: column;
  gap: 100px;
  margin-top: 40px;
  padding-top: 12px;
  background-color: white;
  @media (max-width: 1023px) {
    gap: 80px;
  }
}
</style>
